import React from 'react';
import styled, { css } from 'styled-components';

const Box = styled.div`
  position: relative;
  display: inline-block;
  width: 35px;
  height: 24px;
`;

const burgerLine = css`
  position: absolute;
  width: 35px;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  border-radius: 3px;
  background-color: #000;
`;

const Inner = styled.div`
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  background-color: #000000;
  top: 50%;
  display: block;
  margin-top: -2px;
  ${burgerLine};

  &:after,
  &:before {
    ${burgerLine};
  }
  &::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    background-color: #000000;
    display: block;
    content: '';
    top: -10px;
  }

  &::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    background-color: #000000;
    bottom: -10px;
    display: block;
    content: '';
  }
`;

interface HamburgerProps {
  active: boolean;
}

const HamburgerBody = styled.div<HamburgerProps>`
  font: inherit;
  display: inline-block;
  overflow: visible;
  height: 55px;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;

  &:hover {
    opacity: 0.7;
  }

  ${props =>
    props.active &&
    css`
      ${Inner} {
        transform: rotate(225deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
          top: 0;
          opacity: 0;
          transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.1s ease-out,
            transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    `};
`;

interface OwnProps {
  className?: string;
  active: boolean;
  setActive: (active: boolean) => void;
}

const Hamburger = ({ className, active, setActive }: OwnProps) => {
  return (
    <HamburgerBody className={className} active={active} onClick={() => setActive(!active)}>
      <Box>
        <Inner />
      </Box>
    </HamburgerBody>
  );
};

export default styled(Hamburger)``;
