import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { ActiveProps } from 'models/Props';
import { mediaMin } from 'common/styledMixins';
import { breakpoint, color, zIndex } from 'common/styledVars';
import Hamburger from 'components/Hamburger';

export const StyledLink = styled(Link)`
  font-size: 18px;
  font-weight: 500;
  padding: 11px 0;
  white-space: nowrap;
`;

export const LogoContainer = styled.div`
  grid-column: 1 / 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  z-index: ${zIndex.header + 5};
  padding: 0 15px;

  a {
    display: flex;
  }


  @media ${mediaMin(breakpoint.desktop)} {
    flex-direction: row;
    margin-top: 0;
    padding: 0 40px;
  }

`;

export const NavigationContainerDesktop = styled.div`
  padding: 0 40px;
  position: absolute;
  visibility: visible;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  z-index: ${zIndex.header + 4};

  @media ${mediaMin(breakpoint.desktop)} {
    display: grid;
  }
`;

export const NavigationContainerMobile = styled.div<ActiveProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  overflow: hidden;
  display: flex;
  padding: 0 15px;

  transition: all 0.5s;
  z-index: ${zIndex.header + 2};
  background-color: ${color.white};
  border-bottom: 1px solid #e3ebe9;


  & > div {
    transition: all 0.8s;
    opacity: 0;
  }

  ${props =>
    props.active &&
    css`
      overflow: auto;
      height: 100vh;

      & > div {
        opacity: 1;
      }
    `}

  @media ${mediaMin(breakpoint.desktop)} {
    display: none;
  }
`;

export const CenterColumn = styled.div`
  grid-column: 2 / 3;
  grid-row: 1;
  display: flex;

  & > div:not(:first-of-type) {
    margin-left: 29px;
  }
`;

export const RightColumn = styled.div`
  grid-column: 3 / 4;
  grid-row: 1;
  justify-self: end;
`;

export const HeaderBackgroundMobile = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 80px;
  background-color: ${color.white};
  z-index: ${zIndex.header + 3};

  @media ${mediaMin(breakpoint.desktop)} {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  min-height: 90px;
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #e3ebe9;
  }

  ${Hamburger} {
    z-index: ${zIndex.header + 4};
  }

  @media ${mediaMin(breakpoint.desktop)} {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${Hamburger} {
      display: none;
    }
  }
`;

export const HeaderWrapper = styled.header`
  width: 100%;
  background-color: #ffffff;
  z-index: ${zIndex.header};

  @media ${mediaMin(breakpoint.desktop)} {
    position: fixed;
  }
`;
