import React, { useContext } from 'react';

import ThemeContext from 'components/ThemeContext';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

import reset from './reset';
import { ActiveProps } from 'models/Props';

const GlobalStyle = createGlobalStyle<ActiveProps>`
  ${normalize()};

  ${reset};

  html, body {
    padding: 0;
    margin: 0;
    min-height: 100%;
    width: 100%;
  }

  @media only screen and (max-width:981px) {
    html, body {
      overflow-x: hidden !important;
    }
  }

  body {
    overflow: ${props => (props.active ? 'hidden' : 'auto')}
  }


  p {
    margin: 0;
    padding: 0;
  }

  #___gatsby {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #ffffff;
    color: #000000;
    font-family: "CircularStd";
    line-height: 1.25;
  }

  div[role="group"][tabindex] {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  * {
    outline: none;
    box-sizing: border-box;
    letter-spacing: 0.2px;
  }

  a {
    text-decoration: none;
    color: #000000;
  }

@font-face {
  font-family: 'CircularStd';
  src:  url('/fonts/circular/CircularStd-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src:  url('/fonts/circular/CircularStd-BlackItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'CircularStd';
  src: url('/fonts/circular/CircularStd-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: url('/fonts/circular/CircularStd-BoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'CircularStd';
  src: url('/fonts/circular/CircularStd-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: url('/fonts/circular/CircularStd-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'CircularStd';
  src: url('/fonts/circular/CircularStd-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: url('/fonts/circular/CircularStd-BookItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

`;

const GlobalStyleComponent = () => {
  const theme = useContext(ThemeContext);

  return <GlobalStyle active={theme.navigation.active} />;
};

export default GlobalStyleComponent;
