import React from 'react';
import styled from 'styled-components';

import { mediaMin } from 'common/styledMixins';
import { breakpoint } from 'common/styledVars';

interface OwnProps {
  children: React.ReactNode;
}

const MainContainer = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${mediaMin(breakpoint.desktop)} {
    margin-top: 90px;
  }
`;

const Main = ({ children }: OwnProps) => {
  return <MainContainer>{children}</MainContainer>;
};

export default Main;
