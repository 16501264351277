import React from 'react';
import styled from 'styled-components';
import { BaseTransparentP, BaseP } from 'components/typography';
import { ShipkooLogo } from 'components/icons/ShipkooLogo';
import { FooterContainer, Container, StyledLink, Copyright } from './styled';
import { aboutUsLinks, servicesLinks, resourcesLinks, socialMediaLinks, companyLinks, featureLinks } from '../navigation';
import Facebook from 'images/facebook2.png';
import Linkedin from 'images/linkedin.png';
import Twitter from 'images/twitter.png';
import Medium from 'images/medium.png';
import Youtube from 'images/youtube-logo.png';
import Spot from 'images/spot-logo.png';
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl";
const { useState } = React;

const SocialMedia = styled.div`
    a {
      display: inline-block;
    }

    img {
      height: auto;
      width: 85%;
    }

    .youtube-logo {
      max-width: 40px;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      margin-top: 0px;
    }

`;

const WidthContainer = styled(Container)`
  margin-top: 55px;
  position: initial;
  max-width: 1255px;

  .arrow {
    border: solid #00AF85;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    position: absolute;
    margin-top: 12px;
    margin-left: 10px;
  }

  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .more-contents {
    display:none
  }

  .more-contents.active {
    display:block;
  }

  .contact-p {
    margin-bottom: 20px;
    color: #fff;
  }

  .links-container {
    max-width:183px;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 600px;
    background-color: black;
    color: #fff;
    // text-align: center;
    border-radius: 6px;
    padding: 20px 20px 0 20px;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 0;
    margin-left: -270px;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  @media only screen and (max-width: 981px) {
    .footer-column {
      margin-bottom: 10px !important;
    }
  }

  .contact-more-items {
    display: none;
  }

  .contact-info-icon {
    display: none;
  }


  @media only screen and (min-width: 1024px) {
    .shipkoo-logo {
      margin-bottom: 30px !important;
    }
    .contact-info-icon {
      display: inline-block;
      vertical-align: text-bottom;
      margin-left: 10px;
    }
  }

  .terms-privacy-link {
    color: #9db1ad;
    font-size: 16px;
    font-weight: 300;
    padding-top: 5px;
  }

  @media only screen and (max-width: 768px)  {
    .terms-privacy-link {
      color: #9db1ad;
      font-size: 14px;
      font-weight: 300;
      padding-top: 5px;
    }
    .more-contents {
      display: block;
    }

    .more-link {
      display: none;
    }

    .links-container {
      margin-top: -7px;
      max-width: 100%;
    }
  }

  .styled__StyledLink {
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
  }

  .more-contents a {
    margin-bottom: 7px;
    display: block;
  }

  .hidden {
    display:none !important;
  }

  @media only screen and (max-width: 768px)  {
    .styled__StyledLink {
      font-size:16px;
    }
  }

  @media only screen and (max-width: 1024px)  {
    // .tooltip .tooltiptext {
    //   visibility: hidden;
    //   width: 320px;
    //   background-color: black;
    //   color: #fff;
    //   border-radius: 6px;
    //   padding: 20px 20px 0 20px;
    //   position: absolute;
    //   z-index: 1;
    //   left: 20%;
    //   margin-left: 0;
    //   bottom: -128px;
    // }

    .tooltip .tooltiptext {
      display: none;
    }
    
    // .tooltip .tooltiptext::after {
    //   content: "";
    //   position: absolute;
    //   top: 50%;
    //   right: 100%;
    //   left: -5px;
    //   margin-top: -5px;
    //   border-width: 5px;
    //   border-style: solid;
    //   border-color: transparent black transparent transparent;
    // }
  }

`;

const WidthContainerBottom = styled(Container)`
  margin-top: 15px;
  position: initial;
  max-width: 1255px;

  .arrow {
    border: solid #00AF85;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    position: absolute;
    margin-top: 12px;
    margin-left: 10px;
  }

  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .more-contents {
    display:none
  }

  .more-contents.active {
    display:block;
  }

  .links-container {
    max-width:183px;
  }

  @media only screen and (max-width: 981px) {
    .footer-column {
      margin-bottom: 10px !important;
    }
  }

  @media only screen and (min-width: 1024px) {
    .shipkoo-logo {
      margin-bottom: 30px !important;
    }


    .address-1 {
      margin-bottom: 7px !important;
    }

    .contact-footer-container {
        display: none;
    }

  .terms-privacy-link {
    color: #9db1ad;
    font-size: 16px;
    font-weight: 300;
    padding-top: 5px;
  }

  @media only screen and (max-width: 768px)  {
    .terms-privacy-link {
      color: #9db1ad;
      font-size: 14px;
      font-weight: 300;
      padding-top: 5px;
    }
    .more-contents {
      display: block;
    }

    .more-link {
      display: none;
    }

    .links-container {
      margin-top: -7px;
      max-width: 100%;
    }
  }

  .styled__StyledLink {
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
  }

  .more-contents a {
    margin-bottom: 7px;
    display: block;
  }

  .hidden {
    display:none !important;
  }

  @media only screen and (max-width: 768px)  {
    .styled__StyledLink {
      font-size:16px;
    }
  }

`;

const FooterCopyright = styled.div`
  width: 100%;
  .site-container {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 20px;
    color: #a6b1af;
  }
  
  .terms-privacy-link {
    color: #a6b1af;
  }

`;

const SpotButton = styled.div`
  position: fixed;
  bottom: 130px;
  right: 10px;
  z-index: 9999;

  .spot {
    border-radius:10px;
    border: 1px solid #13c939;
    max-width: 115px;
  }
`;


const Footer = () => {
  const intl = useIntl();
  const [active, setActive] = useState(true);
  const [contactActive, setContactActive] = useState(true);
  const hoverHandler = () => {
    setActive(!active);
  };

  const contactHoverHandler = () => {
    setContactActive(!contactActive);
  };

  return (
    <>
      <FooterContainer>
        <WidthContainer>
          <div>
            <Link className="shipkoo-logo" to="/">
              <ShipkooLogo />
            </Link>
            <SocialMedia>
              <a href="https://www.facebook.com/Shipkoo.Shipping" target="_blank" rel="noreferrer"><img src={Facebook} alt="faceook logo" /></a>
              <a href="https://linkedin.com/company/shipkoo" target="_blank" rel="noreferrer"><img src={Linkedin} alt="linkedin logo" /></a>
              <a href="https://twitter.com/shipkoo" target="_blank" rel="noreferrer"><img src={Twitter} alt="twitter logo" /></a>
              <a href="https://medium.com/@shipkoo" target="_blank" rel="noreferrer"><img src={Medium} alt="medium logo" /></a>
              <a href="https://m.youtube.com/@shipkoolimited" target="_blank" rel="noreferrer"><img src={Youtube} alt="youtube logo" className="youtube-logo"/></a>
            </SocialMedia>
            {/* <Copyright>©   {new Date(Date.now()).getFullYear()} {intl.formatMessage({ id: "footer-copyright" })}</Copyright>
            <Link className="terms-privacy-link" to="/terms-and-conditions">{intl.formatMessage({ id: "footer-terms" })}</Link>
            <Link className="terms-privacy-link" to="/privacy-policy">{intl.formatMessage({ id: "footer-privacy" })}</Link> */}
          </div>
          <div className="footer-column">
            <BaseTransparentP>{intl.formatMessage({ id: "footer-company" })}</BaseTransparentP>
            <Link className="styled__StyledLink" to="/our-story/">{intl.formatMessage({ id: "menu-our-story" })}</Link>
            <Link className="styled__StyledLink" to="/blog">{intl.formatMessage({ id: "menu-blog" })}</Link>
            <Link className="styled__StyledLink" to="/partner-couriers-and-warehousing/">{intl.formatMessage({ id: "menu-partners" })}</Link>
          </div>
          <div className="footer-column">
            <BaseTransparentP>{intl.formatMessage({ id: "footer-services" })}</BaseTransparentP>
            <Link className="styled__StyledLink" to="/services/express-shipping/">{intl.formatMessage({ id: "menu-express-shipping" })}</Link>
            <Link className="styled__StyledLink" to="/services/ecommerce-fulfillment/">{intl.formatMessage({ id: "menu-ecommerce-fulfillment" })}</Link>
            <Link className="styled__StyledLink" to="/services/fba-prep-and-forwarding/">{intl.formatMessage({ id: "menu-fba-prep" })}</Link>
            <Link className="styled__StyledLink" to="/services/last-mile-delivery/">{intl.formatMessage({ id: "menu-last-mile-delivery" })}</Link>
            <Link className="styled__StyledLink" to="/services/sourcing/">{intl.formatMessage({ id: "menu-sourcing" })}</Link>
            <Link className="styled__StyledLink" to="/services/amazon-distribution-center/">{intl.formatMessage({ id: "menu-amazon-distribution-center" })}</Link>
            <Link className="styled__StyledLink" to="/services/us-customs-clearance/">{intl.formatMessage({ id: "menu-us-customes-clearance" })}</Link>
            <Link className="styled__StyledLink" to="/services/shipping-from-china/">{intl.formatMessage({ id: "menu-sourcing-from-china2" })}</Link>
          </div>
          <div className="footer-column">
            <BaseTransparentP>{intl.formatMessage({ id: "footer-features" })}</BaseTransparentP>
            <div className="tooltip">
              <Link className="styled__StyledLink" to="/contact">
                {intl.formatMessage({ id: "menu-contact" })}
                <svg stroke="#00AF85" fill="#00AF85" stroke-width="0" version="1.1" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" className="contact-info-icon"><path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z"></path><path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z"></path><path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z"></path></svg>
              </Link>
              <div className="tooltiptext">
                <p className="address-1 contact-p">Room 32, 11/F, Lee Ka Industrial Building, 8 Ng Fong Street, San Po Kong, Kowloon, Hong Kong.</p>
                <p className="contact-p">A-16–12, Tropicana Avenue, No 12, persiaran Tropicana Country& golf resort, PJU 3,47410 Petaling jaya, Selangor , Malaysia.</p>
                <p className="contact-p">31259 Wiegman Rd.Hayward, CA94544</p>
                <a href="tel:60126737037" className="contact-p">+6012 673 7037</a>
                <br />
                <a href="tel:+1(718)838-9809" className="contact-p">+1(718)838-9809</a>
                <a href="mailto:info@shipkoo.com"><p className="contact-p">info@shipkoo.com</p></a>
              </div>
            </div>
            {/* <div className="links-container contact-more-items" onMouseEnter={contactHoverHandler} onMouseLeave={contactHoverHandler}>
              <a className="styled__StyledLink more-link" href="#">{intl.formatMessage({ id: "menu-contact" })} <i className={(contactActive ? 'arrow down' : 'arrow up active')}></i></a>
              <div className={(contactActive ? 'more-contents' : 'more-contents active')}>
                <p className="address-1 contact-p">Room 32, 11/F, Lee Ka Industrial Building, 8 Ng Fong Street, San Po Kong, Kowloon, Hong Kong.</p>
                <p className="contact-p">A-16–12, Tropicana Avenue, No 12, persiaran Tropicana Country& golf resort, PJU 3,47410 Petaling jaya, Selangor , Malaysia.</p>
                <a href="tel:601151298872" className="contact-p">+6011 5129 8872</a>
                <a href="mailto:info@shipkoo.com" className="contact-p">info@shipkoo.com</a>
              </div>
            </div> */}
            <Link className="styled__StyledLink" to="/ecommerce-store-integration/">{intl.formatMessage({ id: "menu-platform-integrations" })}</Link>
            <Link className="styled__StyledLink" to="/case-studies/">{intl.formatMessage({ id: "menu-case-studies" })}</Link>
            <Link className="styled__StyledLink" to="/ecommerce-store-integration/api-docs/orders-shipments-api/">{intl.formatMessage({ id: "menu-api-docs" })}</Link>
          </div>
          <div className="footer-column">
            <BaseTransparentP>{intl.formatMessage({ id: "footer-resources" })}</BaseTransparentP>
            <a className="styled__StyledLink" target="_blank" href={intl.formatMessage({ id: "tracker-link" })} rel="noreferrer" >{intl.formatMessage({ id: "menu-track-shipment" })}</a>
            {/*<a className="styled__StyledLink" target="_blank" href={intl.formatMessage({ id: "calculator-link" })} >{intl.formatMessage({ id: "menu-shipping-calculator" })}</a>*/}
            <Link className="styled__StyledLink" to="/resources/international-shipping">{intl.formatMessage({ id: "menu-international-shipping" })}</Link>
            <div className="links-container" onMouseEnter={hoverHandler} onMouseLeave={hoverHandler}>
              <a className="styled__StyledLink more-link" href="#">{intl.formatMessage({ id: "more-menu" })} <i className={(active ? 'arrow down' : 'arrow up active')}></i></a>
              <div className={(active ? 'more-contents' : 'more-contents active')} >
                {/* <Link className="styled__StyledLink" to="/resources/holiday-shipping">{intl.formatMessage({ id: "menu-holiday-shipping" })}</Link> */}
                <Link className="styled__StyledLink" to="/resources/fulfillment-centers-vs-warehouse">{intl.formatMessage({ id: "menu-fulfillment-centers-vs-warehouse" })}</Link>
                <Link className="styled__StyledLink" to="/resources/all-in-one-digital-platform/">{intl.formatMessage({ id: "menu-all-in-one-digital-platform" })}</Link>
                <Link className="styled__StyledLink" to="/resources/local-fulfillment-vs-origin-fulfillment/">{intl.formatMessage({ id: "menu-local-vs-origin" })}</Link>
                {/*<Link className="styled__StyledLink" to="/pricing">{intl.formatMessage({ id: "menu-pricing" })}</Link>*/}
                <Link className="styled__StyledLink" to="/case-studies">{intl.formatMessage({ id: "menu-case-studies" })}</Link>
              </div>
            </div>
          </div>
        </WidthContainer>
      </FooterContainer>

      <FooterCopyright>
        <div className="site-container">
          <a href='https://spotvirtual.com/invite/shipkoo-7f3def0342aec6bd-2OjsLNh51D' target={'_blank'} rel="noreferrer"><SpotButton><img src={Spot} alt="spot logo" className='spot'/></SpotButton></a>
          <Copyright>©   {new Date(Date.now()).getFullYear()} {intl.formatMessage({ id: "footer-copyright" })}</Copyright><br />
          <Link className="terms-privacy-link" to="/terms-and-conditions">{intl.formatMessage({ id: "footer-terms" })}</Link> |
          <Link className="terms-privacy-link" to="/privacy-policy"> {intl.formatMessage({ id: "footer-privacy" })}</Link>
        </div>
      </FooterCopyright>

      <WidthContainerBottom>
        <div className="contact-footer-container">
          <p className="address-1 contact-p">Room 32, 11/F, Lee Ka Industrial Building, 8 Ng Fong Street, San Po Kong, Kowloon, Hong Kong.</p>
          <p className="contact-p">A-16–12, Tropicana Avenue, No 12, persiaran Tropicana Country & golf resort, PJU 3,47410 Petaling jaya, Selangor , Malaysia.</p>
          <p className="contact-p">31259 Wiegman Rd.Hayward, CA94544</p>
          <a href="tel:60126737037" className="contact-p">+6012 673 7037</a>
          <a href="tel:+1(718)838-9809" className="contact-p">+1(718)838-9809</a>
          <a href="mailto:info@shipkoo.com" className="contact-p">info@shipkoo.com</a>
        </div>
      </WidthContainerBottom>
    </>
  );
};

export default Footer;

