import React from 'react';
import styled from 'styled-components';
import MobileDropdown from './MobileDropdown';
import { navigationLinks, contact as contactLink } from '../navigation';
import { StyledLink } from './styled';
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 95px 40px 0;
  width: 100%;

  & > ${StyledLink} {
    &:last-of-type {
      margin: auto auto 0;
      padding-bottom: 25px;
    }
  }

  .sign-in {
    font-size: 18px;
    font-weight: 500;
    padding: 11px 0;
    white-space: nowrap;
  }
  .tracker {
    margin-left:30px;
    font-size: 18px;
    font-weight: 500;
    padding: 11px 0;
    white-space: nowrap;
  }

  .MobileDropdown__Container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
    -webkit-transition: all 1s;
    transition: all 1s;
  }

  .MobileDropdown__Container .Button__BaseButton:hover {
    cursor:pointer;

  }

  .MobileDropdown__Container.language-selector {
    text-transform:uppercase;
  }

  .MobileDropdown__Container.language-selector .MobileDropdown__DropdownBox {
    text-transform:uppercase;
  }

  .MobileDropdown__Container .Button__BaseButton {
    display: inline-block;
    position: relative;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    text-align: center;
    background: transparent;
    padding: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 11px 0;
  }

  .MobileDropdown__Container:hover .MobileDropdown__DropdownArrow {
    -webkit-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    transform: scaleY(-1);
  }
  .MobileDropdown__Container:hover .MobileDropdown__DropdownItems {
    height:auto;
    opacity:1;
  }

  .MobileDropdown__MobileText {
    font-size: 18px;
    font-weight: 500;
  }

  .MobileDropdown__DropdownArrow {
    height: 10px;
    width: 11px;
    -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
  }

  .MobileDropdown__DropdownArrow g {
    fill: #00AF85;
  }

  .MobileDropdown__DropdownItems {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    margin-left: 20px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    height: 0;
    opacity: 0;
  }

  .styled__StyledLink {
    font-size: 18px;
    font-weight: 500;
    padding: 11px 0;
    white-space: nowrap;
  }

  .hidden {
    display:none !important;
  }

`;

const GetStarted = styled.button`
    border-radius: 10px;
    background-color: #00AF85;
    color: #ffffff;
    padding: 2px 20px;
    height: 32px;
    font-size: 14px;
    border:0;
    font-weight: 300;
    line-height: 31px;
    text-align: center;
    white-space: nowrap;
    cursor:pointer;
    margin-right: auto;
    margin-left: 0;
    margin-top:10px;
`;

const NavigationMobile = () => {
  const intl = useIntl()
  return (
    <Container>
      <div className="MobileDropdown__Container">
        <button className="Button__BaseButton MobileDropdown__DropdownBox">
          <Link className="styled__StyledLink" to="/our-story/"><span className="MobileDropdown__MobileText">{intl.formatMessage({ id: "menu-about-us" })}</span></Link>
          <svg className="MobileDropdown__DropdownArrow" fill="#232142"   x="0px" y="0px" width="284.929px" height="284.929px" viewBox="0 0 284.929 284.929"><g><path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441   L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082   c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647   c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"></path></g></svg>
        </button>
        <div className="MobileDropdown__DropdownItems">
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "blog-link" })}>{intl.formatMessage({ id: "menu-blog" })}</a>
          {/* <a className="styled__StyledLink" href={intl.formatMessage({ id: "partners-link" })}>{intl.formatMessage({ id: "menu-partners" })}</a> */}
          <Link className="styled__StyledLink" to="/partner-couriers-and-warehousing/">{intl.formatMessage({ id: "menu-partners" })}</Link>
        </div>
      </div>
      <Link className="styled__StyledLink" to="/case-studies/">{intl.formatMessage({ id: "menu-case-studies" })}</Link>
      <div className="MobileDropdown__Container">
        <button className="Button__BaseButton MobileDropdown__DropdownBox">
          <span className="MobileDropdown__MobileText">{intl.formatMessage({ id: "menu-services" })}</span><svg className="MobileDropdown__DropdownArrow" fill="#232142"   x="0px" y="0px" width="284.929px" height="284.929px" viewBox="0 0 284.929 284.929"><g><path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441   L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082   c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647   c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"></path></g></svg>
        </button>
        <div className="MobileDropdown__DropdownItems">
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "express-shipping-link" })}>{intl.formatMessage({ id: "menu-express-shipping" })}</a>
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "ecommerce-fulfillment-link" })}>{intl.formatMessage({ id: "menu-ecommerce-fulfillment" })}</a>
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "crowdfunding-fulfillment-link" })}>{intl.formatMessage({ id: "menu-last-mile-delivery" })}</a>
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "fba-link" })}>{intl.formatMessage({ id: "menu-fba-prep" })}</a>
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "sourcing-link" })}>{intl.formatMessage({ id: "menu-sourcing" })}</a>
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "amazon-link" })}>{intl.formatMessage({ id: "menu-amazon-distribution-center" })}</a>
          {/* <a className="styled__StyledLink" href={intl.formatMessage({ id: "china-link" })}>{intl.formatMessage({ id: "menu-china-air-freight" })}</a> */}
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "us-link" })}>{intl.formatMessage({ id: "menu-us-customes-clearance" })}</a>
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "sourcing-from-china-link" })}>{intl.formatMessage({ id: "menu-sourcing-from-china2" })}</a>
        </div>
      </div>
      <div className="MobileDropdown__Container">
        <button className="Button__BaseButton MobileDropdown__DropdownBox">
          <span className="MobileDropdown__MobileText">{intl.formatMessage({ id: "menu-integrations" })}</span><svg className="MobileDropdown__DropdownArrow" fill="#232142"   x="0px" y="0px" width="284.929px" height="284.929px" viewBox="0 0 284.929 284.929"><g><path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441   L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082   c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647   c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"></path></g></svg>
        </button>
        <div className="MobileDropdown__DropdownItems">
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "ecommerce-store-link" })}>{intl.formatMessage({ id: "menu-platform-integrations" })}</a>
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "order-shipment-link" })}>{intl.formatMessage({ id: "menu-api-docs" })}</a>
        </div>
      </div>
      <div className="MobileDropdown__Container">
        <button className="Button__BaseButton MobileDropdown__DropdownBox">
          <span className="MobileDropdown__MobileText">{intl.formatMessage({ id: "menu-resources" })}</span><svg className="MobileDropdown__DropdownArrow" fill="#232142"   x="0px" y="0px" width="284.929px" height="284.929px" viewBox="0 0 284.929 284.929"><g><path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441   L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082   c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647   c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"></path></g></svg>
        </button>
        <div className="MobileDropdown__DropdownItems">
          <a className="styled__StyledLink" target="_blank" href={intl.formatMessage({ id: "tracker-link" })} rel="noreferrer" >{intl.formatMessage({ id: "menu-track-shipment" })}</a>
          {/*<a className="styled__StyledLink" target="_blank" href={intl.formatMessage({ id: "calculator-link" })} >{intl.formatMessage({ id: "menu-shipping-calculator" })}</a>*/}
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "international-shipping-link" })}>{intl.formatMessage({ id: "menu-international-shipping" })}</a>
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "faq-link" })}>{intl.formatMessage({ id: "menu-faq" })}</a>
          {/* <a className="styled__StyledLink" href={intl.formatMessage({ id: "holiday-shipping-link" })}>{intl.formatMessage({ id: "menu-holiday-shipping" })}</a> */}
          {/* <Link className="styled__Link" to="/resources/faq/">{intl.formatMessage({ id: "menu-faq" })}</Link> */}
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "fulfillment-centers-vs-warehouse-link" })}>{intl.formatMessage({ id: "menu-fulfillment-centers-vs-warehouse" })}</a>
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "aio-link" })}>{intl.formatMessage({ id: "menu-all-in-one-digital-platform" })}</a>
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "lfvof-link" })}>{intl.formatMessage({ id: "menu-local-vs-origin" })}</a>
          {/*<a className="styled__StyledLink" href={intl.formatMessage({ id: "pricing-link" })}>{intl.formatMessage({ id: "menu-pricing" })}</a>*/}
        </div>
      </div>
      <a className="sign-in" href={intl.formatMessage({ id: "contact-link" })}>
        {intl.formatMessage({ id: "menu-contact" })}
      </a>
      <a target="_blank" className="sign-in" href="https://app.shipkoo.com/login" rel="noreferrer">
        {intl.formatMessage({ id: "menu-sign-in" })}
      </a>
      <a target="_blank" href="https://app.shipkoo.com/register" rel="noreferrer">
        <GetStarted>{intl.formatMessage({ id: "menu-get-started" })}</GetStarted>
      </a>
      <div className="MobileDropdown__Container language-selector">
        <button className="Button__BaseButton MobileDropdown__DropdownBox">
          <span className="MobileDropdown__MobileText">{intl.formatMessage({ id: "language" })}</span><svg className="MobileDropdown__DropdownArrow" fill="#232142"   x="0px" y="0px" width="284.929px" height="284.929px" viewBox="0 0 284.929 284.929"><g><path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441   L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082   c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647   c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"></path></g></svg>
        </button>
        <div className="MobileDropdown__DropdownItems">
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "homepage-link" })}>{intl.formatMessage({ id: "language-menu" })}</a>
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "homepage-link-alt" })}>{intl.formatMessage({ id: "language-menu-alt" })}</a>
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "homepage-link-alt2" })}>{intl.formatMessage({ id: "language-menu-alt2" })}</a>
          <a className="styled__StyledLink" href={intl.formatMessage({ id: "homepage-link-alt3" })}>{intl.formatMessage({ id: "language-menu-alt3" })}</a>
        </div>
      </div>
    </Container>
  );
};

export default NavigationMobile;