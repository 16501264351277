import React, { useState } from 'react';

interface ThemeContextI {
  navigation: {
    active: boolean;
    setActive: (state: boolean) => void;
  };
}

const ThemeContext = React.createContext<ThemeContextI>({
  navigation: {
    active: false,
    setActive: () => false,
  },
});

interface OwnProps {
  children: React.ReactNode;
}

export const ThemeProvider = (props: OwnProps) => {
  const [active, setActive] = useState(false);

  return <ThemeContext.Provider value={{ navigation: { active, setActive } }} {...props} />;
};

export default ThemeContext;

