import { Link } from 'gatsby';
import styled from 'styled-components';

import { mediaMin } from 'common/styledMixins';
import { breakpoint } from 'common/styledVars';
import { mainContainerWidth } from 'common/styledVars';
import { navigationText } from 'components/typography';

export const StyledLink = styled(Link)`
  ${navigationText};
`;

export const Copyright = styled.span`
  color: #a6b1af;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
`;

export const FooterContainer = styled.footer`
  position: relative;
  width: 100%;
  padding: 0px 85px 0px 35px;
  display: flex;

  &:after {
    position: absolute;
    content: ' ';
    top: 0;
    right: 0;
    left: 0;
    height: 1px;
    background-color: #ecf2f1;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    padding: 0px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${mainContainerWidth.desktop - 100}px;
  width: 100%;
  padding: 0 15px;
  margin: 120px auto 0;

  & > div {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    & > * {
      margin-bottom: 7px;
    }
  }

  @media ${mediaMin(breakpoint.desktop)} {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 50px;

    & > div {
      display: flex;
      flex-direction: column;

      & > :first-child {
        margin-bottom: 35px;
      }
    }
  }
`;
