import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { ThemeProvider } from 'components/ThemeContext';
import GlobalStyle from './GlobalStyle';
import Header from './Header';
import Footer from './Footer';
import Main from './Main';
import './css/styles.css'

interface OwnProps {
  children: React.ReactNode;
  title?: string;
}

const Layout = ({ title = '', children }: OwnProps) => {
  const intl = useIntl()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = site.siteMetadata.description;

  const url = typeof window !== 'undefined' ? window.location.href : '';
  return (
    <ThemeProvider>
      <Helmet>
        <html lang={intl.formatMessage({ id: "language" })} />
        <link rel="canonical" href={url} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="author" content={intl.formatMessage({ id: "author" })} />
      </Helmet>
      <GlobalStyle />
      <Header />
      <Main>
        {children}</Main>
      <Footer />
    </ThemeProvider>
  );
};

export default Layout;
