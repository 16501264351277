import React, { useContext } from 'react';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import Hamburger from 'components/Hamburger';
import Dropdown from 'components/Dropdown';
import { StyledLink } from 'components/Dropdown/styled';
import ThemeContext from 'components/ThemeContext';
import NavigationMobile from 'components/Layout/Header/NavigationMobile';
import { ShipkooLogo } from 'components/icons/ShipkooLogo';
import {
  HeaderContainer,
  LogoContainer,
  CenterColumn,
  NavigationContainerDesktop,
  NavigationContainerMobile,
  HeaderWrapper,
  HeaderBackgroundMobile,
} from './styled';
import { navigationLinks } from '../navigation';
import styled from 'styled-components';
import Badge from 'components/Badge';
import Covid19Banner from 'components/Layout/Header/covid19-banner';
import Covid19BannerChinese from 'components/Layout/Header/covid19-banner-chinese';
import Covid19BannerKorean from 'components/Layout/Header/covid19-banner-korean';
import Covid19BannerJapanese from 'components/Layout/Header/covid19-banner-japanese';

const ResourcesContainerRight = styled.div`
  margin-left: auto;
  margin-right: auto;
  display:inline-block;
  margin-left:30px;

  .styled__DropdownBody {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #000000;
    font-size: 15px;
    line-height: 18px;
    margin: 0;
  }

  .styled__DropdownBody:hover .styled__DropdownMenu {
    visibility: visible;
    opacity: 1;
  }

  .styled__DropdownBody:hover .styled__Arrow{
    -webkit-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    transform: scaleY(-1);
    fill: #ffffff;
  }

  .styled__Arrow {
    height: 12px;
    width: 14px;
    margin-left: 8px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }

  .styled__DropdownControl {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }

  .styled__Arrow g {
    fill: #00AF85;
  }
  .styled__DropdownBody:hover .styled__DropdownControl .styled__Link {
    color: #00AF85;
  }
  .styled__DropdownMenu {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -18px;
    left: -22px;
    min-width: calc(100% + 44px);
    padding: 54px 22px 0;
    border-radius: 4px;
    background-color: #000000;
    box-shadow: 0 42px 70px 0 rgba(0,0,0,0.34);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .styled__DropdownMenu .styled__MenuItem:last-of-type {
    margin-bottom: 22px;
  }

  .styled__MenuItem {
    display: inline-block;
    margin-top: 8px;
    white-space: nowrap;
    opacity: 0.5;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    text-align:center;
  }

  .styled__MenuItem:hover {
    opacity:1;
  }

  .styled__MenuItem a {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    color: #ffffff;
  }
  .styled__MenuItem a:hover {
    color: rgb(0, 175, 133) !important;
  }
`;

const ResourcesContainer = styled.div`
  margin-left: auto;
  margin-right: auto;

  .styled__DropdownBody {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #000000;
    font-size: 15px;
    line-height: 18px;
    margin: 0;
  }

  .styled__DropdownBody:hover .styled__DropdownMenu {
    visibility: visible;
    opacity: 1;
  }

  .styled__DropdownBody:hover .styled__Arrow{
    -webkit-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    transform: scaleY(-1);
    fill: #ffffff;
  }

  .styled__Arrow {
    height: 12px;
    width: 14px;
    margin-left: 8px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }

  .styled__DropdownControl {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }

  .styled__Arrow g {
    fill: #00AF85;
  }
  .styled__DropdownBody:hover .styled__DropdownControl .styled__Link {
    color: #00AF85;
  }
  .styled__DropdownMenu {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -18px;
    left: -22px;
    min-width: calc(100% + 44px);
    padding: 54px 22px 0;
    border-radius: 4px;
    background-color: #000000;
    box-shadow: 0 42px 70px 0 rgba(0,0,0,0.34);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .styled__DropdownMenu .styled__MenuItem:last-of-type {
    margin-bottom: 22px;
  }

  .styled__MenuItem {
    display: inline-block;
    margin-top: 8px;
    white-space: nowrap;
    opacity: 0.5;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }

  .styled__MenuItem:hover {
    opacity:1;
  }

  .styled__MenuItem a {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    color: #ffffff;
  }
  .styled__MenuItem a:hover {
    color: rgb(0, 175, 133) !important;
  }

  .hidden {
    display:none !important;
  }

`;

const CovidBanner = styled.div`
  text-align: center;
  font-weight: 400;
  color: #fff;
  background: #00af85;

  .en .zh {
    display:none;
  }

  .en .kr {
    display:none;
  }

  .en .ja {
    display:none;
  }

  .zh .en {
    display:none;
  }

  .zh .kr {
    display:none;
  }

  .zh .ja {
    display:none;
  }

  .kr .en {
    display:none;
  }

  .kr .zh {
    display:none;
  }

  .kr .ja {
    display:none;
  }

  .ja .en {
    display:none;
  }

  .ja .zh {
    display:none;
  }

  .ja .kr {
    display:none;
  }

  a {
    color:#fff;
    text-decoration: underline;
  }
`;


const UkraineBannerWrapper = styled.div`
  text-align: center;
  font-weight: 400;
  color: #fff;
  background: #00af85;

  p {
    color:#fff;
    text-decoration: underline;
  }
`;

const GetStarted = styled.button`
    border-radius: 10px;
    background-color: #00AF85;
    color: #ffffff;
    padding: 2px 20px;
    height: 32px;
    font-size: 14px;
    border:0;
    font-weight: 300;
    line-height: 31px;
    text-align: center;
    white-space: nowrap;
    cursor:pointer;
    margin-right: auto;
    margin-left: 25px;
`;

const RightColumn = styled.div`
  grid-column: 3 / 4;
  grid-row: 1;
  justify-self: end;
  width: 370px;
  text-align: right;
`;

const Header = () => {
  const theme = useContext(ThemeContext);
  const { active, setActive } = theme.navigation;
  const intl = useIntl()

  return (
    <HeaderWrapper>
      {/* <CovidBanner>
        <div className={intl.formatMessage({ id: "language" })}>
          <Covid19Banner />
          <Covid19BannerChinese />
          <Covid19BannerKorean />
          <Covid19BannerJapanese />
        </div>
      </CovidBanner> */}
      <HeaderContainer>
        <LogoContainer>
          <Link to="/">
            <ShipkooLogo />
          </Link>
        </LogoContainer>
        <NavigationContainerDesktop>
          <CenterColumn>
            <ResourcesContainer>
              <div className="styled__DropdownBody Dropdown___default">
                <div className="styled__DropdownControl">
                  <Link className="styled__Link" to="/our-story/">{intl.formatMessage({ id: "menu-about-us" })}</Link><svg className="styled__Arrow" fill="#232142" version="1.1"  x="0px" y="0px" width="284.929px" height="284.929px" viewBox="0 0 284.929 284.929" ><g><path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441   L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082   c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647   c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"></path></g></svg>
                </div>
                <div className="styled__DropdownMenu">
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/our-story/">{intl.formatMessage({ id: "menu-our-story" })}</Link>
                  </div>
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/blog">{intl.formatMessage({ id: "menu-blog" })}</Link>
                  </div>
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/partner-couriers-and-warehousing/">{intl.formatMessage({ id: "menu-partners" })}</Link>
                  </div>
                </div>
              </div>
            </ResourcesContainer>
            <ResourcesContainer>
              <div className="styled__DropdownBody Dropdown___default">
                <div className="styled__DropdownControl">
                  <Link className="styled__Link" to="/services/">{intl.formatMessage({ id: "menu-services" })}</Link><svg className="styled__Arrow" fill="#232142" version="1.1"  x="0px" y="0px" width="284.929px" height="284.929px" viewBox="0 0 284.929 284.929" ><g><path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441   L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082   c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647   c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"></path></g></svg>
                </div>
                <div className="styled__DropdownMenu">
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/services/fba-prep-and-forwarding/">{intl.formatMessage({ id: "menu-fba-prep" })}</Link>
                  </div>
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/services/express-shipping/">{intl.formatMessage({ id: "menu-express-shipping" })}</Link>
                  </div>
                  <div className="styled__MenuItem">
                  <Link className="styled__Link" to="/services/ecommerce-fulfillment/">{intl.formatMessage({ id: "menu-ecommerce-fulfillment" })}</Link>
                  </div>
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/services/last-mile-delivery/">{intl.formatMessage({ id: "menu-last-mile-delivery" })}</Link>
                  </div>
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/services/sourcing/">{intl.formatMessage({ id: "menu-sourcing" })}</Link>
                  </div>
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/services/amazon-distribution-center">{intl.formatMessage({ id: "menu-amazon-distribution-center" })}</Link>
                  </div>
                  {/* <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/services/china-air-freight/">{intl.formatMessage({ id: "menu-china-air-freight" })}</Link>
                  </div> */}
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/services/us-customs-clearance/">{intl.formatMessage({ id: "menu-us-customes-clearance" })}</Link>
                  </div>
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/services/shipping-from-china/">{intl.formatMessage({ id: "menu-sourcing-from-china2" })}</Link>
                  </div>
                </div>
              </div>
            </ResourcesContainer>
            <ResourcesContainer>
              <div className="styled__DropdownBody Dropdown___default">
                <div className="styled__DropdownControl">
                  <Link className="styled__Link" to="#">{intl.formatMessage({ id: "menu-integrations" })}</Link><svg className="styled__Arrow" fill="#232142" version="1.1"  x="0px" y="0px" width="284.929px" height="284.929px" viewBox="0 0 284.929 284.929" ><g><path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441   L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082   c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647   c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"></path></g></svg>
                </div>
                <div className="styled__DropdownMenu">
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/ecommerce-store-integration/">{intl.formatMessage({ id: "menu-platform-integrations" })}</Link>
                  </div>
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/ecommerce-store-integration/api-docs/orders-shipments-api/">{intl.formatMessage({ id: "menu-api-docs" })}</Link>
                  </div>
                </div>
              </div>
            </ResourcesContainer>
            <ResourcesContainer>
              <div className="styled__DropdownBody Dropdown___default">
                <div className="styled__DropdownControl">
                  <Link className="styled__Link" to="#" >{intl.formatMessage({ id: "menu-resources" })}</Link><svg className="styled__Arrow" fill="#232142" version="1.1"  x="0px" y="0px" width="284.929px" height="284.929px" viewBox="0 0 284.929 284.929" ><g><path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441   L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082   c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647   c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"></path></g></svg>
                </div>
                <div className="styled__DropdownMenu">
                  <div className="styled__MenuItem">
                    <a className="styled__Link" target="_blank" href={intl.formatMessage({ id: "tracker-link" })} rel="noreferrer">{intl.formatMessage({ id: "menu-track-shipment" })}</a>
                  </div>
                  {/*<div className="styled__MenuItem">
                    <Link className="styled__Link" target="_blank" to={intl.formatMessage({ id: "calculator-link" })} >{intl.formatMessage({ id: "menu-shipping-calculator" })}</Link>
                  </div>*/}
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/resources/international-shipping/">{intl.formatMessage({ id: "menu-international-shipping" })}</Link>
                  </div>
                  {/* <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/resources/holiday-shipping/">{intl.formatMessage({ id: "menu-holiday-shipping" })}</Link>
                  </div> */}
                   <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/resources/faq/">{intl.formatMessage({ id: "menu-faq" })}</Link>
                    {/* <Link className="styled__Link" to="/resources/faq/">Frequently Asked Questions</Link> */}
                  </div>
                  
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/resources/fulfillment-centers-vs-warehouse/">{intl.formatMessage({ id: "menu-fulfillment-centers-vs-warehouse" })}</Link>
                  </div>
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/resources/all-in-one-digital-platform/">{intl.formatMessage({ id: "menu-all-in-one-digital-platform" })}</Link>
                  </div>
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/resources/local-fulfillment-vs-origin-fulfillment/">{intl.formatMessage({ id: "menu-local-vs-origin" })}</Link>
                  </div>
                  {/*<div className="styled__MenuItem">
                    <Link className="styled__Link" to="/pricing/">{intl.formatMessage({ id: "menu-pricing" })}</Link>
                  </div>*/}
                  <div className="styled__MenuItem">
                    <Link className="styled__Link" to="/case-studies/">{intl.formatMessage({ id: "menu-case-studies" })}</Link>
                  </div>
                </div>
              </div>
            </ResourcesContainer>
            {/* <ResourcesContainer>
              <div className="styled__DropdownBody Dropdown___default">
                <div className="styled__DropdownControl">
                  <Link className="styled__Link" to="/contact/">{intl.formatMessage({ id: "menu-contact" })}</Link>
                </div>
              </div>
            </ResourcesContainer> */}
          </CenterColumn>
          <RightColumn>
            <a target="_blank" href={intl.formatMessage({ id: "app-login-link" })} rel="noreferrer">
              {intl.formatMessage({ id: "menu-sign-in" })}
            </a>
            <a target="_blank" href={intl.formatMessage({ id: "app-register-link" })} rel="noreferrer">
              <GetStarted>{intl.formatMessage({ id: "menu-get-started" })}</GetStarted>
            </a>
            <ResourcesContainerRight>
              <div className="styled__DropdownBody Dropdown___default">
                <div className="styled__DropdownControl">
                  <Link className="styled__Link" to="/" >{intl.formatMessage({ id: "language-menu" })}</Link><svg className="styled__Arrow" fill="#232142" version="1.1"  x="0px" y="0px" width="284.929px" height="284.929px" viewBox="0 0 284.929 284.929" ><g><path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441   L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082   c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647   c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"></path></g></svg>
                </div>
                <div className="styled__DropdownMenu">
                  <div className="styled__MenuItem">
                    <a className="styled__Link" href={intl.formatMessage({ id: "homepage-link-alt" })} >{intl.formatMessage({ id: "language-menu-alt" })}</a>
                  </div>
                  <div className="styled__MenuItem">
                    <a className="styled__Link" href={intl.formatMessage({ id: "homepage-link-alt2" })} >{intl.formatMessage({ id: "language-menu-alt2" })}</a>
                  </div>
                  <div className="styled__MenuItem">
                    <a className="styled__Link" href={intl.formatMessage({ id: "homepage-link-alt3" })} >{intl.formatMessage({ id: "language-menu-alt3" })}</a>
                  </div>
                </div>
              </div>
            </ResourcesContainerRight>
          </RightColumn>
        </NavigationContainerDesktop>
        <NavigationContainerMobile active={active}>
          <NavigationMobile />
        </NavigationContainerMobile>
        <Hamburger active={active} setActive={setActive} />
        <HeaderBackgroundMobile />
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
